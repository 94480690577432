.psm_user_profile .profile 
{
    /*min-height: 355px;*/
    width : 100%;
    display: inline-block;
    }

.psm_user_profile figcaption.ratings
{
    margin-top:20px;
    }
.psm_user_profile figcaption.ratings a
{
    color:#3c2683;
    font-size:11px;
    }

    .psm_user_profile figcaption.ratings a:hover
{
    color:#f39c12;
    text-decoration:none;
    }

    .psm_user_profile .divider 
{
    border-top:1px solid rgba(0,0,0,0.1);
    }
    .psm_user_profile .emphasis 
{
    border-top: 4px solid transparent;
    }
    .psm_user_profile  .emphasis:hover 
{
    border-top: 4px solid #3c2683;
    }
    .psm_user_profile  .emphasis h2
{
    margin-bottom:0;
    }
span.tags 
{
    background: #3c2683;
    border-radius: 2px;
    color: #f5f5f5;
    font-weight: bold;
    padding: 2px 4px;
    margin-right: 10px;
    }
    .psm_user_profile  .dropdown-menu 
{
    background-color: #3c2683;    
    box-shadow: none;
    -webkit-box-shadow: none;
    width: 250px;
    margin-left: -125px;
    left: 50%;
    }
    .psm_user_profile  .dropdown-menu .divider 
{
    background:none;    
    }
    .psm_user_profile  .dropdown-menu>li>a
{
    color:#f5f5f5;
    }
    .psm_user_profile  .dropup .dropdown-menu 
{
    margin-bottom:10px;
    }
    .psm_user_profile  .dropup .dropdown-menu:before 
{
    content: "";
    border-top: 10px solid #34495e;
    border-right: 10px solid transparent;
    border-left: 10px solid transparent;
    position: absolute;
    bottom: -10px;
    left: 50%;
    margin-left: -10px;
    z-index: 10;
    }