/*.login-container {
    max-width: 420px;
    margin: 0 auto;
    margin: 0 auto;
    margin-top: 40px;
}

.login-form-button {
    width: 100%;
}*/


.login-container #root {
	font-family: "Montserrat-Regular";
	background-image:url("bg_geocrm.jpg");
	background-position: center center;
	background-repeat: no-repeat;
	background-attachment: fixed;
    background-size: cover;
	height: 100%;
	min-height: 100%
}

.container{
	height: 100%;
	/*align-content définit la façon dont l'espace est réparti entre et autour des éléments le long de l'axe en bloc du conteneur*/
	align-content: center; 
}
.login-container .card{
		margin-top: 70px;
		background-color:#f7f7f7;
}
.login-container .card-body .card-title {
	font-weight: bold;
    color: #3C2683;
    
}
.brand_logo {
	height: 130px;
}
.btn{
	font-size: 18px;
}

.forget-pass{
	float: right;
	font-size: 15px;
	text-decoration: none;
}
.forget-pass a{
	text-decoration: none;
	color: #495057;
}
.copy-right{
	text-align: center;
	color: #fff;
	font-size: 15px;
	font-size: 13px;
    margin-top: 10px;
}
.btn-primary {
    color: #fff;
    background-color: #FECC00;
    border-color: #FECC00;
}

.login-container .btn-primary:hover {
    color: #fff;
    background-color: #d39e00;
    border-color: #d39e00;
}
.login-container .btn-primary:not(:disabled):not(.disabled):active, .show>.btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #E51A1A;
    border-color:  #0062cc;
}
.login-container hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid #495057;
}
.login-container .fa-map-marker{
	color: #FECC00;
	font-size: 40px;
}