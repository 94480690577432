#client_table_length, #client_table_info{
    display: none;
}

#client_table_filter{
    float:  left !important;
}

#route_table_length, #route_table_info{
    display: none;
}

#route_table_filter{
    float:  left !important;
}