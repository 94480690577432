.image-flip.box-shadow{
    min-height: 350px !important;
    margin-bottom: 10px;
}

.btn-primary:hover,
.btn-primary:focus {
    background-color: #3c2683;
    border-color: #3c2683;
    box-shadow: none;
    outline: none;
}

.psm-card .border-secondary{
    border-color: #3c2683 !important;   
}

.psm-card .border-secondary{
    border-color: #3c2683 !important;   
}


.psm-card .card-header{
    background-color: #3c2683 !important;
    text-align: center;
    color: white;
    font-weight: bolder;
    font-size: 20px;
    padding: 5px;
}

.box-shadow {
    box-shadow: 0 10px 16px 0 rgba(0,0,0,0.2),0 6px 10px 0 rgba(0,0,0,0.19) !important
    /*0 10px 16px 0 rgba(0,0,0,0.2),0 6px 20px 0 rgba(0,0,0,0.19) !important;*/
}

.btn-primary {
    color: #fff;
    background-color: #3c2683;
    border-color: #007b5e;
}

section {
   /* padding: 60px 0;*/
}

section .section-title {
    text-align: center;
    color: #3c2683;
    margin-bottom: 50px;
    text-transform: uppercase;
}

.team {
  /*margin-top : 30px;*/
}

.team .card {
    border: none;
    background: #ffffff;
}

.mainflip {
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -ms-transition: 1s;
    -moz-transition: 1s;
    -moz-transform: perspective(1000px);
    -moz-transform-style: preserve-3d;
    -ms-transform-style: preserve-3d;
    transition: 1s;
    transform-style: preserve-3d;
    position: relative;
}

.frontside {
    position: relative;
    -webkit-transform: rotateY(0deg);
    -ms-transform: rotateY(0deg);
    z-index: 2;
    margin-bottom: 30px;
}

.backside {
    position: absolute;
    top: 0;
    left: 0;
    background: white;
    -webkit-transform: rotateY(-180deg);
    -moz-transform: rotateY(-180deg);
    -o-transform: rotateY(-180deg);
    -ms-transform: rotateY(-180deg);
    transform: rotateY(-180deg);
    -webkit-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
    -moz-box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
    box-shadow: 5px 7px 9px -4px rgb(158, 158, 158);
}

.frontside,
.backside {
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden;
    -ms-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transition: 1s;
    -webkit-transform-style: preserve-3d;
    -moz-transition: 1s;
    -moz-transform-style: preserve-3d;
    -o-transition: 1s;
    -o-transform-style: preserve-3d;
    -ms-transition: 1s;
    -ms-transform-style: preserve-3d;
    transition: 1s;
    transform-style: preserve-3d;
}

.frontside .card,
.backside .card {
    min-height: 312px;
}

.backside .card a {
    font-size: 18px;
    color: #3c2683 !important;
}

.frontside .card .card-title,
.backside .card .card-title {
    color: #3c2683 !important;
}

.frontside .card .card-body img {
    width: 120px;
    height: 120px;
    border-radius: 50%;
}