.nav-tabs {
    border-bottom: 0px solid !important;
    margin-bottom: 15px;
    margin-top: 15px;

}

.nav-tabs .nav-link {
    border: 1px solid transparent;
    border-top-left-radius: 0px !important; 
    border-top-right-radius: 0px !important; 
    padding-left: 80px;
    padding-right: 80px;
    background-color: #cacccc;
    border: 1px solid #dfdfdf;
        color: #333333;
}
.nav-tabs .nav-link.active {
    color: #ffffff !important;
    background-color: #3c2683 !important;
    border-color: #3c2683 #3c2683 !important;
    padding-left: 80px;
    padding-right: 80px;
}
#client_histo{
	font-size: 16px;
}
.dataTables_info{
	font-size: 16px;
}
.client_histo_previous {
	font-size: 16px;
}
.libhist{
	color: #3c2683;
}
.libcomd{
	color: #3c2683;
	font-family: "Montserrat-SemiBold";
}