a:focus {
  outline: none;
}
.portofolio{
    /*text-align: center;*/
    padding: 50px 0 40px;
}

.portofolio img{
  width: 150px;
  text-align:center;
  margin-left: 20px;
}
.portfolioFilter {
  padding: 15px 0;
  font-size: 16px;
}

.portfolioFilter a {
  margin-right: 6px;
  color: #666;
  text-decoration: none;
  border: 1px solid #ccc;
  padding: 4px 15px;
  border-radius: .2rem;
  display: inline-block;
  margin-bottom: 10px;
}

.portfolioFilter a.current {
    background: #3c2683;
    border: 1px solid #3c2683;
    color: #f9f9f9;
}
.portfolioContainer{
  border: 1px solid #eee;
  border-radius: 3px;
  width: 100%;
  min-height: 200px
}
.portfolioContainer img {
  margin: 5px;
  max-width:100%;
}

.isotope-item {
  z-index: 2;
}
.portfolioContainer .isotope{
	width: 100% !important;
}
.isotope-hidden.isotope-item {
  pointer-events: none;
  z-index: 1;
}

.isotope,
.isotope .isotope-item {
  -webkit-transition-duration: 0.8s;
  -moz-transition-duration: 0.8s;
  transition-duration: 0.8s;
}

.isotope {
  -webkit-transition-property: height, width;
  -moz-transition-property: height, width;
  transition-property: height, width;
}

.isotope .isotope-item {
  -webkit-transition-property: -webkit-transform, opacity;
  -moz-transition-property: -moz-transform, opacity;
  transition-property: transform, opacity;
}

.fullscreen:-webkit-full-screen {
  width: auto !important;
  height: auto !important;
  margin:auto !important;
}
 .fullscreen:-moz-full-screen {
  width: auto !important;
  height: auto !important;
  margin:auto !important;
}
 .fullscreen:-ms-fullscreen {
  width: auto !important;
  height: auto !important;
  margin:auto !important;
}   