.mapLegende{
    float: right;
}

.mapLegende >  li {
    display: inline-block;
    margin-right: 15px;
    font-family: "Montserrat-Regular";
    color: "#ccc"
}

.mapLegende >  li > i {
    color: black !important;
    font-size: 15px !important;
}

.message{
    text-align: center;
}

.message{
    color: #8DB73D;
    font-family: "Lato-Black";
    font-size: 9px;
    text-align:center;
    margin-top:-25px;
    margin-left: 17px;
}